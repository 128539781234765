
import config from './runtime-config';
export const chatKey = function() {
    return "uI2ooxtwHeI6q69PS98fx9SWVGbpQohO";
}

export const chatURL = function() {
    return "http://localhost:8000";
}

export default chatKey;
