import { RECEIVED_PLUPD_DATA_SUCCESS, RECEIVING_PLUPD_DATA } from '../actions/plansListUpdate';

//const defaultState = { data: {}, isReceiving: false };

export default function plansList(state = {data:{}},{type,payload}) {
    switch (type) {
        case RECEIVED_PLUPD_DATA_SUCCESS:
            return Object.assign({}, state, {
                data:payload,
                isReceiving: false
            });
        case RECEIVING_PLUPD_DATA:
            return Object.assign({}, state, {
                isReceiving: true
            });
        default:
            return state;
    }
}
