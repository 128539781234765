
export const getVersion = function() { 
    var MAJOR=3;
    var MINOR=2;
    var RELEASE=0;
    var COMMIT="a41670cf";
    var BUILD="26";
    return MAJOR + "." + MINOR + "." + RELEASE + "." + BUILD + "-" + COMMIT; 
}

export default getVersion;
